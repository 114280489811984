export default {
	data() {
		return {
			window: {
				width: 0,
			},
		};
	},
	mounted() {
		window.addEventListener('resize', this.handleResize);
		this.handleResize();
	},
	destroyed() {
		window.removeEventListener('resize', this.handleResize);
	},
	methods: {
		handleResize() {
			setTimeout(() => {
				this.window.width = document.getElementById('getWidth').offsetWidth;
			}, 400);
		},
	},
};
